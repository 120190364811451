import mutations from './mutations'

const state = () => ({
  work: { id: null, name: null },
  workMenuName: null,
  workSubMenuName: null,
  isLoadingWork: false,
  workteam: null,
  workUserRole: null,
  workUserPermissions: [],
  constructionStages: [],
  units: []

})

const actions = {

}

const getters = {
  isLoadingWork: (state) => state.isLoadingWork,
  work: (state) => state.work,
  workMenuName: (state) => state.workMenuName,
  workSubMenuName: (state) => state.workSubMenuName,
  workteam: (state) => state.workteam,
  workUserRole: (state) => state.workUserRole,
  workUserPermissions: (state) => state.workUserPermissions,
  constructionStages: (state) => state.constructionStages,
  units: (state) => state.units
}

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters
}
