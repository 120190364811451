import mutations from './mutations'

const state = () => ({
  channelArktop: {
    activeArktopUsers: [],
    userArktopMovement: { user: null, side: null },
    updatedAt: null
  }

})

const actions = {

}

const getters = {
  channelArktop: (state) => state.channelArktop
}

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters
}
