export default {

  updateChannelArktop (state, { key, value }) {
    state.channelArktop[key] = value
  },
  updateChannelArktopPushUser (state, user) {
    state.channelArktop.activeArktopUsers.push(user)
  },
  updateChannelArktopSpliceUser (state, payload) {
    const userindex = state.channelArktop.activeArktopUsers.findIndex((usr) => usr.id === payload.userId)
    state.channelArktop.activeArktopUsers.splice(userindex, 1)
  }
}
