import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes.js'
import clients from './clients.js'
import biddings from './biddings.js'
import subscriptions from './subscriptions.js'
import store from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'active',
  routes: [...routes, ...biddings, ...clients, ...subscriptions],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  store.commit('setRouteMetaLayout', to.meta.layout)
  if (to.meta && to.meta.layout && to.meta.layout === 'auth') {
    store.commit('setLayout', 'auth')
  } else {
    store.commit('setLayout', 'app')
  }
  if (to.name !== 'login' && to.name !== 'company-selector') {
    // Vou gravar a página de navegação para retornar para ela depois
    if (Vue.$cookies.isKey('user_active_company')) {
      Vue.$cookies.set('arktop_navigation_page', {
        companyId: Vue.$cookies.get('user_active_company').id,
        page: to.path
      })
    }
  }

  next(true)
})
router.afterEach((to, from) => {})

export default router
