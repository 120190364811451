<template>
  <div class="widget widget-iugu-accounts">
    <div class="widget-heading">
      <h5>Contas</h5>
      <b-link
        v-b-tooltip.hover
        :to="{ name: 'finance-account-statements' }"
        title="Extratos"
      >
        <i class="fa-regular fa-money-check-pen" />
      </b-link>
    </div>

    <div class="widget-content">
      <div
        v-if="isLoading"
        class="row justify-content-center"
      >
        <div class="col-12 p-3 text-center">
          <div>
            <b-spinner
              variant="primary"
              label="Spinning"
            />
          </div>
        </div>
      </div>
      <div
        v-if="!isLoading"
        class="custom-table"
      >
        <b-table-simple responsive>
          <b-thead>
            <b-tr>
              <b-th>
                <div class="th-content">
                  Titular
                </div>
              </b-th>
              <b-th>
                <div class="th-content text-center">
                  Tipo
                </div>
              </b-th>
              <b-th>
                <div class="th-content text-center">
                  Saldo
                </div>
              </b-th>
              <b-th>
                <div class="th-content text-center">
                  Atualizar
                </div>
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="(account, index) in accounts"
              :key="index"
            >
              <b-td>
                <div class="td-content">
                  {{ account.account.accountable.name }}
                </div>
              </b-td>
              <b-td class="text-center">
                <div class="td-content">
                  <span
                    v-if="
                      account.account.accountable_type.includes(
                        'User'
                      )
                    "
                    class="small"
                  >PF</span>
                  <span
                    v-else
                    class="small"
                  >PJ</span>
                </div>
              </b-td>
              <b-td class="text-center">
                <div class="td-content">
                  <span class="small">{{
                    account.account.balance
                  }}</span>
                </div>
              </b-td>
              <b-td class="text-center">
                <div class="td-content">
                  <b-link
                    @click="
                      handleIuguAccountBalance(
                        account.account.iugu_account_id
                      )
                    "
                  >
                    <i
                      v-show="
                        loadingBalance !=
                          account.account.iugu_account_id
                      "
                      class="fa-solid fa-sync"
                    />
                    <i
                      v-show="
                        loadingBalance ==
                          account.account.iugu_account_id
                      "
                      class="fa-solid fa-sync spin"
                    />
                  </b-link>
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../common/api.service'

export default {
  filters: {},
  data () {
    return {
      userPermissions: [],
      isLoading: false,
      loadingBalance: null,
      accounts: []
    }
  },
  computed: {},
  watch: {},
  mounted: function () {
    this.getInitialIuguAccountsData()
  },
  methods: {
    getInitialIuguAccountsData: function () {
      this.isLoading = true

      ApiService.post('finance/getInitialIuguAccountsData', {
        userId: this.$store.state.auth.user.id
      })
        .then((response) => {
          this.accounts = response.data.accounts
          this.userPermissions = response.data.userPermissions
        })
        .finally(() => {
          this.isLoading = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    handleIuguAccountBalance: async function (iuguAccountId) {
      const accountIndex = this.accounts.findIndex(
        (item) => item.account.iugu_account_id === iuguAccountId
      )

      this.loadingBalance = iuguAccountId
      try {
        const response = await this.getIuguAccountBalance(
          iuguAccountId
        )
        this.accounts[accountIndex].account.balance =
                    response.data.balance.toFixed(2)
        this.accounts[
          accountIndex
        ].account.balance_available_for_withdraw =
                    response.data.balance_available_for_withdraw.toFixed(2)
        this.accounts[accountIndex].account.autowithdraw =
                    response.data.autowithdraw
        this.account = this.accounts[accountIndex]
      } catch (error) {
        this.loadingBalance = null
        // toastr.error('Ocorreu um erro durante a consulta.');
      } finally {
        this.loadingBalance = null
      }
    },
    getIuguAccountBalance: async function (iuguAccountId) {
      return ApiService.post('finance/getIuguAccountBalance', {
        iuguAccountId
      })
    }
  }
}
</script>

<style scoped>
#modal-iugu-transfer___BV_modal_outer_ {
    z-index: 1800 !important;
}
</style>
