<template>
  <div class="layout-px-spacing">
    <portal to="breadcrumb">
      <ul class="navbar-nav flex-row">
        <li>
          <div class="page-header">
            <nav
              class="breadcrumb-one"
              aria-label="breadcrumb"
            >
              <ol class="breadcrumb">
                <li
                  class="breadcrumb-item active"
                  aria-current="page"
                >
                  <span>{{ $t("dashboard") }}</span>
                </li>
              </ol>
            </nav>
          </div>
        </li>
      </ul>
    </portal>
    <b-row class="layout-top-spacing">
      <b-col class="layout-spacing">
        <b-row>
          <b-col>
            <!-- <horizontal-works v-if="$checkPermission('ver-obras')" /> -->
            <widget-works v-if="$checkPermission('ver-obras')" />
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col
            cols="12"
            sm="6"
          >
            <div class="widget">
              <iugu-accounts v-if="$checkPermission('see-arktop-accounts-component')" />
            </div>
          </b-col>
          <b-col
            cols="12"
            sm="6"
          >
            <div class="widget widget-sales-category">
              <div class="widget-heading">
                <h5>Usuários Online</h5>
              </div>
              <div class="widget-content">
                <b-list-group
                  flush
                  class="chat-container overflow-auto"
                >
                  <b-list-group-item
                    v-for="(user, index) in channelArktop.activeArktopUsers"
                    :key="index"
                    style="cursor: pointer"
                    @click="$router.push({name: 'edit-user', params:{userId: user.id}})"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        sm="12"
                      >
                        <b-avatar
                          variant="info"
                          :src="user.avatar"
                          class="mr-3"
                        />
                        <span class="mr-auto">{{ user.name }}</span>
                      </b-col>
                      <!-- <b-col
                        cols="12"
                        sm="6"
                        class="text-right"
                      >
                        <span v-if="user.companies.length == 0"> - </span>
                        <span
                          v-for="(company, indexComp) in user.companies"
                          :key="indexComp"
                        >
                          {{ company.name }}<br>
                        </span>
                      </b-col> -->
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import '@/assets/sass/widgets/widgets.scss'
import '@/assets/sass/widgets/widgets-arktop.scss'
import iuguAccounts from '../finance/iugu-accounts.vue'
// import horizontalWorks from './horizontal-works.vue'
import widgetWorks from './widget-works.vue'
import { mapState } from 'vuex'

export default {
  name: 'ArktopIndex',
  metaInfo: { title: 'Dashboard' },
  components: {
    iuguAccounts,
    widgetWorks
    // horizontalWorks
  },
  data () {
    return {}
  },
  computed: {
    ...mapState({
      channelArktop: (state) => state.chArktop.channelArktop
    })
  },
  beforeMount () {

  },
  mounted () {},
  methods: {}
}
</script>
<style scoped>
    .layout-px-spacing {
        min-height: calc(100vh - 170px) !important;
    }
</style>
