<template>
  <div :class="[$store.state.layout_style, $store.state.menu_style]">
    <component :is="layout" />
  </div>
</template>
<script>
// layouts
import appLayout from './layouts/app-layout.vue'
import authLayout from './layouts/auth-layout.vue'

import '@/assets/sass/app.scss'

export default {
  metaInfo: {
    title: 'Arktop',
    titleTemplate: '%s | Arktop'
  },
  components: {
    app: appLayout,
    auth: authLayout
  },
  data () {
    return {}
  },
  computed: {
    layout () {
      return this.$store.getters.layout
    }
  },
  mounted () {},
  methods: {}
}
</script>
