const routes = [
  {
    path: '/work/:workId/suppliers',
    name: 'work-suppliers',
    component: () => import(/* webpackChunkName: "work-suppliers" */ '../views/work/biddings/suppliers.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },
  {
    path: '/work/:workId/biddings',
    name: 'work-biddings',
    component: () => import(/* webpackChunkName: "work-biddings" */ '../views/work/biddings/index.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  },

  {
    path: '/work/:workId/biddings/:biddingId',
    name: 'work-bidding',
    component: () =>
      import(/* webpackChunkName: "work-bidding" */ '../views/work/biddings/bidding.vue'),
    meta: { section_name: 'work', layout: 'work' },
    props: (route) => {
      return {
        ...route.params,
        ...{
          workId: Number.parseInt(route.params.workId, 10),
          biddingId: Number.parseInt(route.params.biddingId, 10)
        }
      }
    }
  }

]
export default routes
