const routes = [
  {
    path: '/admin/subscriptions',
    name: 'subscriptions',
    component: () =>
      import(/* webpackChunkName: "subscriptions" */ '../views/admin/subscriptions/subscriptions.vue')
  },
  {
    path: '/admin/subscriptions/plans',
    name: 'subscription-plans',
    component: () => import(/* webpackChunkName: "subscription-plans" */ '../views/admin/subscriptions/plans.vue')
  },
  {
    path: '/admin/subscriptions/new-plan',
    name: 'new-subscription-plan',
    component: () =>
      import(/* webpackChunkName: "new-subscription-plan" */ '../views/admin/subscriptions/plan.vue')
  },
  {
    path: '/admin/subscription/plan/:planId',
    name: 'edit-subscription-plan',
    component: () =>
      import(/* webpackChunkName: "edit-subscription-plan" */ '../views/admin/subscriptions/plan.vue'),
    props: true
  },
  {
    path: '/user/subscription',
    name: 'user-subscription',
    component: () => import(/* webpackChunkName: "user-subscription" */ '../views/user/user-subscription.vue')
  }

]
export default routes
