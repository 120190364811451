<template>
  <div>
    <!--  BEGIN NAVBAR  -->
    <Header />
    <!--  END NAVBAR  -->

    <!--  BEGIN MAIN CONTAINER  -->
    <div
      id="container"
      class="main-container"
      :class="[
        !$store.state.is_show_sidebar ? 'sidebar-closed sbar-open' : '',
        $store.state.menu_style === 'collapsible-vertical' ? 'collapsible-vertical-mobile' : '',
      ]"
    >
      <!--  BEGIN OVERLAY  -->
      <div
        class="overlay"
        :class="{ show: !$store.state.is_show_sidebar }"
        @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)"
      />
      <div
        class="search-overlay"
        :class="{ show: $store.state.is_show_search }"
        @click="$store.commit('toggleSearch', !$store.state.is_show_search)"
      />
      <!-- END OVERLAY -->

      <!--  BEGIN SIDEBAR  -->
      <Sidebar />
      <!--  END SIDEBAR  -->

      <!--  BEGIN CONTENT AREA  -->

      <div
        id="content"
        class="main-content"
        :class="$store.state.routeMetaLayout === 'work' ? 'horizontal-content' : null"
      >
        <div
          v-if="showWorkMenu"
          class="sub-menu"
        >
          <work-menu />
        </div>
        <router-view>
          <!-- BEGIN LOADER -->
          <div id="load_screen">
            <div class="loader">
              <div class="loader-content">
                <div class="spinner-grow align-self-center" />
              </div>
            </div>
          </div>
          <!--  END LOADER -->
        </router-view>

        <!-- BEGIN FOOTER -->
        <!-- <Footer></Footer> -->
        <!-- END FOOTER -->
      </div>
      <!--  END CONTENT AREA  -->

      <!-- BEGIN APP SETTING LAUNCHER -->
      <!-- <app-settings /> -->
      <!-- END APP SETTING LAUNCHER -->
    </div>
  </div>
</template>
<script>
import ApiService from '../common/api.service'
import Header from '@/components/layout/header.vue'
import Sidebar from '@/components/layout/sidebar.vue'
import WorkMenu from '@/components/layout/work-menu.vue'
// import Footer from '@/components/layout/footer.vue';
// import moment from 'moment';
// import appSettings from '@/components/app-settings.vue';
// import { mapState } from 'vuex';

export default {
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Header,
    Sidebar,
    WorkMenu
    // Footer,
    // appSettings,
  },
  data () {
    return {
      showWorkMenu: false
    }
  },
  computed: {
    workId: function () {
      return this.$route.params.workId
    }

  },
  watch: {
    workId: function () {
      // setTimeout(() => {
      if (this.$route.params.workId && this.$store.state.routeMetaLayout === 'work') {
        this.getInitialWorkData(this.$route.params.workId)
        // this.showWorkMenu = true
      } else {
        this.showWorkMenu = false
      }
      // }, 1500)
    }
    // 'state.alert_variant': function (value) {
    //   if (value == true) {
    //     console.log()
    //     this.alerttime = moment().format('h:mm:ss')
    //     this.showToast()
    //   }
    // }
  },
  methods: {
    getInitialWorkData: function (workId) {
      this.$store.commit('updateIsLoadingWork', true)
      ApiService.get(`work/getInitialWorkData/${workId}`).then((response) => {
        if (response.data.status === 'success') {
          this.$store.commit('updateWork', response.data.work)
          this.$store.commit('updateWorkTeam', response.data.workteam.members)
          this.$store.commit('updateWorkUserRole', response.data.workUserRole)
          this.$store.commit('updateWorkUserPermissions', response.data.workUserPermissions)
          this.$store.commit('updateConstructionStages', response.data.constructionStages)
          this.$store.commit('updateUnits', response.data.units)
          this.showWorkMenu = true
        } else {
          this.showWorkMenu = false
        }
      })
        .finally(() => {
          this.$store.commit('updateIsLoadingWork', false)
        })
    }
  }
}
</script>
<style scoped>
    .sub-menu {
        position: -webkit-sticky;
        position: sticky;
        top: 54px;
        z-index: 12 !important;
        box-shadow: 0px 20px 20px rgb(126 142 177 / 12%);
    }

</style>
