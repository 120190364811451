export default {

  updateIsLoadingWork (state, value) {
    state.isLoadingWork = value
  },
  updateWork (state, value) {
    state.work = value
  },
  updateWorkMenuName (state, value) {
    state.workMenuName = value
  },
  updateWorkSubMenuName (state, value) {
    state.workSubMenuName = value
  },
  updateWorkTeam (state, value) {
    state.workteam = value
  },
  updateWorkUserRole (state, value) {
    state.workUserRole = value
  },
  updateWorkUserPermissions (state, value) {
    state.workUserPermissions = value
  },
  updateConstructionStages (state, value) {
    state.constructionStages = value
  },
  updateUnits (state, value) {
    state.units = value
  }
}
