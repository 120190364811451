/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */
import Vue from 'vue'
import router from '../router'
import store from '../store/index'
import Echo from 'laravel-echo'
import JwtService from './jwt.service'
import { EventBus } from './event-bus'
import Notify from './notification'

let serverURL
let key = null

if (process.env.NODE_ENV === 'production') {
  serverURL = 'https://app.arktop.com.br/api/broadcasting/auth'
  key = '8bd9526218dc5f6c3378'
} else {
  serverURL = 'https://app.arktop.test/api/broadcasting/auth'
  key = '9b53228db42d151eb85b'
}

const EchoService = {
  init () {
    window.Pusher = require('pusher-js')

    // Pusher.log = (message) => {
    //   if (window.console && window.console.log) {
    //     window.console.log(message);
    //   }
    // };

    window.Echo = new Echo({
      broadcaster: 'pusher',
      key,
      cluster: 'mt1',
      encrypted: true,
      authEndpoint: serverURL,
      auth: {
        headers: {
          Authorization: `Bearer ${JwtService.getToken()}`
        }
      }
    })

    window.Echo.connector.pusher.connection.bind('connected', () => {
    //   store.commit('updatePusherStatus', 'connected')
    })
    window.Echo.connector.pusher.connection.bind('disconnected', () => {
    //   store.commit('updatePusherStatus', 'disconnected')
    })

    setTimeout(() => {
      window.Echo.channel(`private-App.User.${store.state.auth.user.id}`)
        .listen('NotifyUserOnPrivateChannel', (e) => {
        // console.log(e);
        })
      // .error((error) => {
      //   store.commit('updatePusherStatus', error.type)
      // })
    }, 3000)
  }
}

export default EchoService
