const routes = [
  {
    path: '/client/work/:workId/scope/:quoteId/view',
    name: 'client-work-scope-view',
    component: () => import(/* webpackChunkName: "client-work-scope-view" */ '../views/work/scope/client/client-scope-view.vue'),
    props: (route) => {
      return { ...route.params, ...{ workId: Number.parseInt(route.params.workId, 10), quoteId: Number.parseInt(route.params.quoteId, 10) } }
    },
    meta: { section_name: 'work', layout: 'work' }
  }
]
export default routes
